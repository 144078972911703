import { Button } from '@newageerp/crm-ui';
import { FieldInput, FieldLabel, Wide, WideRow } from '@newageerp/v3.bundles.form-bundle';
import { toast } from '@newageerp/v3.templates.templates-core';
import React, { Fragment, useEffect, useState } from 'react'

type Props = {
  orderId: string,
}

export default function ReportItemShippingAddressUpdate(props: Props) {
  const [shippingAddress, setShippingAddress] = useState<{ [key: string]: string }>({});
  const updateKey = (key: string, val: string) => {
    const _shippingAddress = JSON.parse(JSON.stringify(shippingAddress));
    _shippingAddress[key] = val;
    setShippingAddress(_shippingAddress);
  }

  const appendSession = async () => {
    const url = '/app/plugins/CrmSession/appendSession';
    const res = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({
          orderId: props.orderId,
          extraData: {
            manualShippingAddress: shippingAddress
          }
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    toast.success('Saved');

  }

  const getData = async () => {
    const url = '/app/plugins/CrmSession/getShippingAddress';
    const res = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({
          orderId: props.orderId
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    const resJson = await res.json();

    if (resJson && resJson.success === 1 && !!resJson.shippingAddress) {
      setShippingAddress(resJson.shippingAddress);
    }
  }

  useEffect(() => {
    getData().catch(console.error);
  }, [props.orderId])

  if (!shippingAddress) {
    return <Fragment />
  }

  const keys = Object.keys(shippingAddress);

  return (

    <Wide>
      {keys.map(k => {
        return (
          <WideRow key={`k-${k}`}
            label={<FieldLabel>{k}</FieldLabel>}
            control={
              <FieldInput className='w-full' value={shippingAddress[k]} onChange={e => updateKey(k, e.target.value)} />

            }
          />
        )
      })}
      <Button.FormButton onClick={appendSession}>Save</Button.FormButton>
    </Wide>
  )
}
