import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useURemove, useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';

interface Props {
    fieldKey: string,
}

export default function ShippingOrderAction(props: Props) {
    const { element } = useListTableRow();
    const list = useListDataSource();
    const [doSave, doSaveReq] = useUSave('shipping-order');
    const [doRemove, doRemoveReq] = useURemove('shipping-order');

    const doCancel = async () => {
        await doSave({
            status: 20,
        }, element.id)
        list.data.reload.do();
    }
    const doRestore = async () => {
        await doSave({
            status: 0,
        }, element.id)
        list.data.reload.do();
    }
    const onRemove = async () => {
        await doRemove(element.id);
        list.data.reload.do();

    }


    if (element.status === 0) {
        return (
            <div className='flex gap-x-2'>
                <MainButton loading={doSaveReq.loading} confirmation={true} onClick={doCancel} color='amber'>Cancel</MainButton>

                <ToolbarButton confirmation={true} iconName='trash' onClick={onRemove} />
            </div>
        )
    }
    if (element.status === 20) {
        return (
            <MainButton loading={doSaveReq.loading} confirmation={true} onClick={doRestore} color='sky'>Restore</MainButton>
        )
    }


    return <Fragment />
}
