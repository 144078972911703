import { useDataCache } from '@newageerp/v3.app.data-cache-provider'
import React, { Fragment, useState } from 'react'
import { Card, Tabs, Button } from '@newageerp/crm-ui'
import ReportItemShippingAddress from '../../../../ReportItem/Shipping/ReportItemShippingAddress';
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle';
import ReportItemShippingAddressUpdate from '../../../../ReportItem/Shipping/ReportItemShippingAddressUpdate';

type Props = {
  element: any
}

export default function ReportItemShipping(props: Props) {
  const { system } = useDataCache();

  const isShipping = !!system.data.config.shipping && system.data.config.shipping.enabled;

  const [isGenerating, setIsGenerating] = useState(false);
  const generateShippingOrders = async () => {
    setIsGenerating(true);
    await axiosInstance.post(
      '/app/plugins/ShipmentAmazon/fillPaymentsByOrderId',
      { orderId: props.element.orderId }
    );
    setIsGenerating(false);
  }

  if (!isShipping) return <Fragment />

  return (
    <Card.WhiteCard header={{
      title: "Shipping"
    }}>
      <Tabs.Default
        content={{
          right: <Button.ToolbarButton
            iconName='plus'
            border='none'
            small={true}
            bgColor='white'
            onClick={generateShippingOrders}
            loading={isGenerating}
          >
            Generate shipping orders
          </Button.ToolbarButton>
        }}
        tabs={[
          {
            tab: {
              title: 'Address'
            },
            content: <ReportItemShippingAddress orderId={props.element.orderId} />
          },
          {
            tab: {
              title: 'Address update'
            },
            content: <ReportItemShippingAddressUpdate orderId={props.element.orderId} />
          },
          {
            tab: {
              title: 'Shipping orders'
            },
            content: <TemplatesLoader
              templateName="ListRelContent"
              data={{
                schema: 'shipping-order',
                type: 'main',
                rel: 'reportItem',
                id: props.element.id,
                wrap: 0,
              }}
            />
          }
        ]}
      />
    </Card.WhiteCard>
  )
}
