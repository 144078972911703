import { MpChartSettings } from '@newageerp/mp-types'

export const reportItemChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week_year",
    "aggregate": [
        {
            "column": "order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}


export const appUserChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week_year",
    "aggregate": [
        {
            "column": "is_registered",
            "function": "sum",
            "title": "Test",
            "chartType": "line"
        },
    ]
}

export const subscriptionCancelChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week_year",
    "aggregate": [
        {
            "column": "subscription.order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}

export const subscriptionCancelByStartDateChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "subscription.calendar_week_year",
    "aggregate": [
        {
            "column": "subscription.order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}


export const paymentRefundChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week_year",
    "aggregate": [
        {
            "column": "main_order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}

export const paymentCharbeBackChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "compl_calendar_week_year",
    "aggregate": [
        {
            "column": "main_order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}
